<template>
  <div class="h-100">
    <CCard class="h-100">
      <CCardHeader>
        <CRow align-horizontal="between">
          <CCol col="6"> <CIcon name="cil-grid" /> لیست بنرهای تبلیغاتی محصولات </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CRow></CRow>
        <CRow>
          <CCol md="6" class="h-100">
            <div id="cardBoxList" :style="cardBoxStyle">
              <div
                class="cardBox"
                v-for="(item, index) in rowData"
                :key="index"
                @click="showModal(item.id,'detail')"
              >
                <div class="cardBoxHeader">
                  <img :src="getSrc('Banner', item.bannerImage)" alt="" />
                </div>
                <div class="cardBoxBody">
                  <h4>{{ item.name }}</h4>
                  <CIcon
                    :name="
                      item.type == '1'
                        ? 'cilMovie'
                        : item.type == '2'
                        ? 'cilWallpaper'
                        : 'cilMusicNote'
                    "
                    class="bannerType"
                  />
                  <h5>{{ item.label }}</h5>
                </div>
              </div>
            </div>
          </CCol>
          <CCol md="6">
            <div id="itemDetailPreview" :style="cardBoxStyle" v-if="subForm != null">
              <component
                v-if="subForm != null"
                v-bind:is="subForm"
                :bannerId="selectedRow"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../constants/config";
import detail from "./detail.vue";

export default {
  name: "bannerProductList",
  components: {
    detail,
  },
  data() {
    return {
      rowData: null,
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      windowHeight: window.innerHeight - 200,
      cardBoxStyle: "",
      selectedRow: 0,
      subForm:null
    };
  },

  methods: {
    ...mapActions("bannerProduct", ["GetAllClient", "GetById"]),
    getSrc(foolder, src) {
      return `${apiUrlRoot}/BannerProduct/${foolder}/${src}`; //apiUrlRoot + src;
    },
    async fetchData() {
      this.loading = true;
      var result = await this.GetAllClient({});
      this.loading = false;
      if (result) {
        this.cardBoxStyle = `height: ${this.windowHeight}px;min-height: ${this.windowHeight}px;max-height: ${this.windowHeight}px;`;
        this.rowData = result;
        this.showModal(result[0].id,"detail");
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
    showModal(id, componentName) {
      this.subForm = null;
      this.selectedRow = id;
      this.subForm = componentName;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.userType {
  width: 100%;
  float: right;
  background-color: #fff;
  cursor: pointer !important;
  border-radius: 0px;
  border-right: 2px solid #f80;
}
#fileTypeBox {
  float: right;
  clear: both;
  width: 67%;
  margin-right: 25%;
}
.fileType {
  border: 1px solid #bbb;
  border-radius: 7px;
  float: right;
  margin: 0px 6% 0px 3px;
  width: 56px;
  padding-top: 4px;
  cursor: pointer;
}
.fileType:hover {
  box-shadow: 0px 0px 10px 1px #6fd820;
}

.fileTypeDeSelect h6 {
  background-color: #f80;
}
.fileTypeSelect h6 {
  background-color: #6fd820;
}

.fileType h6 {
  height: 30px;
  margin: 0px;
  padding-top: 6px;
  border-radius: 0px 0px 6px 6px;
  color: #fff;
  text-align: center;
}
.fileType .img {
  color: #f80;
  text-align: center;
  margin-right: 35%;
}

h2 {
  font-size: 14px !important;
  direction: ltr !important;
}

.inputBox {
  padding: 7px;
  box-shadow: 1px 0px 3px 0px #bbb;
  margin: 2px 0px 8px 0px;
  outline: none;
}
.inputValue {
  height: 30px;
  margin: 0px;
  float: right;
  clear: both;
  width: 100%;
  border: 1px solid #e1e1e1;
  outline: none;
  border-right: 2px solid #f78401;
}
.selectValue {
  height: 30px;
  margin: 0px;
  float: right;
  clear: both;
  width: 100%;
}
.inputLabel {
  float: right;
  font-size: 13px;
  margin-top: 6px;
}

.imageSelectedPreview {
  width: 47%;
  min-height: 200px;
  margin: 7px;
  float: right;
}
.imageSelectedPreview video {
  width: 100%;
  height: 300px;
}
.imageSelectedPreview audio {
  width: 100%;
}
.btnFileUpload {
  background-color: rgb(9, 164, 225);
  box-shadow: 0px 0px;
  float: right;
  font-size: 13px;
  height: 32px;
  padding: 2px;
  width: 42%;
  margin-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#cardBoxSearch {
  height: 50px;
  border-bottom: 3px solid #460041;
}
#cardBoxList {
  overflow-y: auto;
}
.cardBox {
  float: right;
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 3px 0px #000;
  margin: 30px;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
}
.cardBox:hover {
  box-shadow: 0px 0px 7px 3px #99ff4c;
}

.cardBoxHeader {
  float: right;
  width: 100%;
  height: 150px;
  border-bottom: 1px solid #bbb;
}
.cardBoxHeader img {
  width: 100%;
  height: inherit;
}
.cardBoxBody {
  float: right;
  width: 100%;
  height: 100px;
  border-radius: 0px 0px 10px 10px;
  background-color: #fff;
  box-shadow: 0px 8px 11px 0px #e5e5e5;
}
.cardBoxBody:hover {
  background-color: #99ff4c;
  border-radius: 0px 0px 10px 10px;
}
.cardBoxBody h4 {
  text-align: center;
    width: 100%;
    float: right;
    clear: both;
    color: #ffffff;
    background-color: #404d6a;
    font-size: 14px;
    font-family: 'Dirooz';
    padding: 3px 0px 2px 0px;
}
.cardBoxBody h5 {
  text-align: center;
  width: 100%;
  float: right;
  clear: both;
}

.bannerType {
  margin-right: 43%;
  width: 35px !important;
  height: 35px !important;
  border-radius: 100%;
  padding: 3px;
  color: #460041;
  text-align: center;
}

.bannerLabel {
  width: 95%;
  float: right;
  margin: 7px;
  background-color: #ddd;
}
.bannerLabel .bannerIcon {
  float: right;
  color: #fff;
    background-color: #3c475f;
  width: 30px !important;
  height: 20px !important;
}
.bannerLabel h5 {
  float: right;
  text-align: right;
  padding-right: 7px;
  padding-top: 4px;
  margin: 0px;
  color: #000;
  font-size: 13px;
}

#itemDetailPreview {
  width: 100%;
  box-shadow: 0px 0px 4px 0px #000;
  border-radius: 4px;
}
</style>
